import styles from "./SelectButton.module.scss";

const SelectButton = ({ title, selected = false, onClick }) => (
  <div
    className={[styles.button, selected && styles.selected].join(" ")}
    onClick={onClick}
  >
    {title}
  </div>
);

export default SelectButton;
