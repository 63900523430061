import logoImage from "../../assets/images/logo.png";
import styles from "./Nav.module.scss";

const Nav = () => (
  <div className={styles.container}>
    <img src={logoImage} />
  </div>
);

export default Nav;
