import React from "react";
import { Robot } from "../../../assets/icons";
import SelectButton from "../../../ui/SelectButton/SelectButton";
import SwiperNav from "../../../ui/SwiperNav/SwiperNav";

import styles from "./ChooseWorkoutStyle.module.scss";

const workoutStyles = ["strength", "mobility", "hiit"];
const ChooseWorkoutStyle = ({ workoutStyle, setWorkoutStyle, onSwipe }) => (
  <div className={styles.container}>
    <Robot width={80} height={80} className={styles.icon} />
    <div className={styles.title}>Choose your workout style</div>

    <div className={styles.list}>
      {workoutStyles.map((style, key) => (
        <SelectButton
          title={style}
          selected={workoutStyle === style}
          key={key}
          onClick={() => setWorkoutStyle(style)}
        />
      ))}
    </div>

    <SwiperNav
      onBack={() => onSwipe(2)}
      onNext={() => !!workoutStyle && onSwipe(4)}
    />
  </div>
);

export default ChooseWorkoutStyle;
