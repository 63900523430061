import Button from "../../ui/Button/Button";
import Wave from "../Wave/Wave";
import styles from "./Header.module.scss";

const Header = () => {
  const handleClick = () => {
    const element = document.getElementById("ai-body");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Meet Fit! AI Coach</div>
      <div className={styles.description}>
        Customize your workouts on the fit app using our ai coach and over 50k
        exercise videos.
      </div>
      <Button style={styles.button} onClick={handleClick}>
        Try now
      </Button>

      <Wave />
    </div>
  );
};

export default Header;
