import CoachResultItem from "./Item";
import BottomWave from "../BottomWave/BottomWave";

import styles from "./CoachResult.module.scss";

const CoachResult = ({ recommendations = [] }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Your AI Workout results</div>

        <div className={styles.result}>
          {recommendations.map((recommendation, key) => (
            <CoachResultItem {...recommendation} key={key} index={key + 1} />
          ))}
        </div>

        <div className={styles.footerTitle}>
          Try unlimited AI searches in the app along with over 100 workout
          programs
        </div>

        <div className={styles.footerDesc}>
          Get a <span style={{ fontWeight: 700 }}>FREE TRIAL</span> for 7 days,
          then $59/year
        </div>

        <a
          href="https://www.joinfitapp.com/paymentFolder/allAccessPage?page=all-access&source=fitaiweb"
          style={{ textDecoration: "none" }}
        >
          <div className={styles.workoutBtn}>Try now</div>
        </a>
      </div>

      <BottomWave />
    </>
  );
};

export default CoachResult;
