import aboutImg from "../../assets/images/about.png";
import styles from "./About.module.scss";

const About = () => (
  <div className={styles.container}>
    <div className={styles.imageWrap}>
      <img src={aboutImg} />
    </div>
    <div className={styles.info}>
      <div className={styles.title}>About</div>
      <div className={styles.description}>
        The Fit! App has over 5,000 reviews and 50k exercises from top fitness
        influencers. Customize your workouts, enjoy easy offline download
        viewing on the fit app, plus get access to over 50 fitness programs
        designed by professionals.
      </div>
    </div>
  </div>
);

export default About;
