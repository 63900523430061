import { useRef, useState } from "react";
import { PlayButton } from "../../assets/icons";
import styles from "./Item.module.scss";

const CoachResultItem = ({
  index,
  title,
  subtitle,
  video_thumbnail,
  video,
}) => {
  const videoRef = useRef();
  const [played, setPlayed] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <span>{index}</span>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.workoutInfo}>{subtitle}</div>
        </div>
      </div>
      <div>
        <video
          ref={videoRef}
          src={video}
          poster={video_thumbnail}
          controls={played}
          controlsList="nodownload"
        />
        {!played && (
          <PlayButton
            width={64}
            height={64}
            className={styles.playBtn}
            onClick={() => {
              setPlayed(true);
              videoRef.current?.play();
            }}
          />
        )}
      </div>

      <div className={styles.divider} />
    </div>
  );
};

export default CoachResultItem;
