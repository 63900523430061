import React from "react";
import { Robot } from "../../../assets/icons";
import SelectButton from "../../../ui/SelectButton/SelectButton";
import SwiperNav from "../../../ui/SwiperNav/SwiperNav";

import styles from "./SelectBody.module.scss";

const bodyParts = [
  "full body",
  "chest",
  "back",
  "biceps",
  "shoulder",
  "legs",
  "glutes",
  "hamstring",
  "abs",
  "triceps",
  "calves",
];
const SelectBody = ({ bodyPart, setBodyPart, onSwipe }) => (
  <div className={styles.container}>
    <Robot width={80} height={80} className={styles.icon} />
    <div className={styles.title}>
      What body parts would you like to work on?
    </div>

    <div className={styles.list}>
      {bodyParts.map((body, key) => (
        <SelectButton
          title={body}
          selected={body === bodyPart}
          key={key}
          onClick={() => setBodyPart(body)}
        />
      ))}
    </div>

    <SwiperNav showBack={false} onNext={() => !!bodyPart && onSwipe(1)} />
  </div>
);

export default SelectBody;
