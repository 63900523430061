import waveImage from "../../assets/images/wave.png";
import image1 from "../../assets/images/1.png";
import image2 from "../../assets/images/2.png";
import styles from "./Wave.module.scss";
import SvgDownArrow from "../../assets/icons/DownArrow";

const Wave = () => (
  <div className={styles.container}>
    <img src={waveImage} className={styles.wave} />
    <div className={styles.div}>
      <img src={image1} className={styles.img1} />
      <img src={image2} className={styles.img2} />
    </div>
    <div className={styles.arrowWrap}>
      <SvgDownArrow width={36} height={36} className={styles.downArrow} />
    </div>
  </div>
);

export default Wave;
