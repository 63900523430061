import { BackArrow, NextArrow } from "../../assets/icons";

import styles from "./SwiperNav.module.scss";

const SwiperNav = ({ showNext = true, showBack = true, onNext, onBack }) => {
  return (
    <div className={styles.container}>
      {showBack && (
        <div className={styles.button} onClick={onBack}>
          <BackArrow width={20} height={20} />
          <span className={styles.ml10}>Back</span>
        </div>
      )}

      {showNext && (
        <div
          className={[styles.button, styles.nextBtn].join(" ")}
          onClick={onNext}
        >
          <span className={styles.mr10}>Next</span>
          <NextArrow width={20} height={20} />
        </div>
      )}
    </div>
  );
};

export default SwiperNav;
