import ClipLoader from "react-spinners/ClipLoader";

import styles from "./LoadingView.module.scss";

const LoadingView = () => (
  <div className={styles.container}>
    <ClipLoader
      color="#FF5818"
      cssOverride={{
        margin: "0 auto",
      }}
      size={80}
      className={styles.icon}
    />
    <div className={styles.title}>
      Please don't close the window! Fit AI is generating your workout...
    </div>
  </div>
);

export default LoadingView;
