import waveImage from "../../assets/images/wave.png";
import styles from "./BottomWave.module.scss";

const BottomWave = () => (
  <div className={styles.container}>
    <img src={waveImage} className={styles.wave} />
  </div>
);

export default BottomWave;
