import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import ChooseWorkoutStyle from "./ChooseWorkoutStyle/ChooseWorkoutStyle";
import SelectBody from "./SelectBody/SelectBody";
import SelectEquipment from "./SelectEquipment/SelectEquipment";

import "swiper/css";
import "swiper/css/pagination";
import styles from "./AIBody.module.scss";
import DurationSlider from "./DurationSlider/DurationSlider";
import { useRef, useState } from "react";
import EnterEmail from "./EnterEmail/EnterEmail";
import LoadingView from "./LoadingView/LoadingView";

const AIBody = ({ loading = false, onSubmit }) => {
  const swiperRef = useRef();
  const [bodyPart, setBodyPart] = useState("");
  const [equipments, setEquipments] = useState([]);
  const [minutes, setMinutes] = useState(1);
  const [workoutStyle, setWorkoutStyle] = useState("");
  const [email, setEmail] = useState("");

  const handleSwipe = (n) => swiperRef.current?.swiper?.slideTo(n);

  const onClick = () => {
    const attributes = `${bodyPart}, ${equipments.join(
      ", "
    )}, ${minutes} min, ${workoutStyle}, ${email}`;
    onSubmit(attributes);
  };

  return (
    <div className={styles.container} id="ai-body">
      <div className={styles.beta}>BETA</div>
      <div className={styles.title}>Customize your workout with Fit! AI</div>
      {loading ? (
        <LoadingView />
      ) : (
        <Swiper
          ref={swiperRef}
          pagination={true}
          modules={[Pagination]}
          className={styles.swiper}
          allowTouchMove={false}
        >
          <SwiperSlide>
            <SelectBody
              bodyPart={bodyPart}
              setBodyPart={setBodyPart}
              onSwipe={handleSwipe}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SelectEquipment
              equipments={equipments}
              setEquipments={setEquipments}
              onSwipe={handleSwipe}
            />
          </SwiperSlide>
          <SwiperSlide>
            <DurationSlider
              minutes={minutes}
              setMinutes={setMinutes}
              onSwipe={handleSwipe}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ChooseWorkoutStyle
              workoutStyle={workoutStyle}
              setWorkoutStyle={setWorkoutStyle}
              onSwipe={handleSwipe}
            />
          </SwiperSlide>
          <SwiperSlide>
            <EnterEmail
              email={email}
              setEmail={setEmail}
              onSwipe={handleSwipe}
              onClick={onClick}
            />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
};

export default AIBody;
