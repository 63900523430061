import { Email, Robot } from "../../../assets/icons";
import SwiperNav from "../../../ui/SwiperNav/SwiperNav";

import styles from "./EnterEmail.module.scss";

const EnterEmail = ({ email, setEmail, onClick, onSwipe }) => {
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className={styles.container}>
      <Robot width={80} height={80} className={styles.icon} />
      <div className={styles.title}>Enter your email to view workouts</div>

      <div className={styles.emailField}>
        <Email width={24} height={24} />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <SwiperNav showNext={false} onBack={() => onSwipe(3)} />

      <div
        className={styles.workoutBtn}
        onClick={() => validateEmail(email) && onClick()}
      >
        Preview workout
      </div>
    </div>
  );
};

export default EnterEmail;
