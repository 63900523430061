import axios from "axios";
import React, { useState } from "react";
import AIBody from "./components/AIBody/AIBody";
import About from "./components/About/About";
import CoachResult from "./components/CoachResult/CoachResult";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";

import "./ui/theme.scss";

function App() {
  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] = useState(null);

  const onSubmit = async (attributes) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://live.joinfitapp.com/api/v1/workout_wizard",
        {
          attributes,
        }
      );

      setRecommendations(response?.data?.response?.recommendations || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <Nav />
      {recommendations ? (
        <CoachResult recommendations={recommendations} />
      ) : (
        <>
          <Header />
          <AIBody loading={loading} onSubmit={onSubmit} />
          <About />
        </>
      )}

      <Footer />
    </div>
  );
}

export default App;
