import ReactSlider from "react-slider";
import SwiperNav from "../../../ui/SwiperNav/SwiperNav";
import { Robot } from "../../../assets/icons";

import styles from "./DurationSlider.module.scss";

const DurationSlider = ({ minutes, setMinutes, onSwipe }) => (
  <div className={styles.container}>
    <Robot width={80} height={80} className={styles.icon} />
    <div className={styles.title}>How long should your workout be?</div>

    <ReactSlider
      min={1}
      max={60}
      value={minutes}
      className={styles.slider}
      onChange={setMinutes}
      renderThumb={(props) => <div {...props} className={styles.thumb}></div>}
      renderTrack={(props, index) => (
        <div
          {...props}
          className={[styles.track, index.index === 0 && styles.progress].join(
            " "
          )}
        ></div>
      )}
    />

    <div className={styles.minutes}>
      {minutes === 1 ? "1min" : `${minutes}mins`}
    </div>

    <SwiperNav onBack={() => onSwipe(1)} onNext={() => onSwipe(3)} />
  </div>
);

export default DurationSlider;
