import React from "react";
import SelectButton from "../../../ui/SelectButton/SelectButton";
import SwiperNav from "../../../ui/SwiperNav/SwiperNav";
import { Robot } from "../../../assets/icons";

import styles from "./SelectEquipment.module.scss";

const equipmentList = [
  "bodyweight",
  "fit! home gym",
  "fit! p-bars",
  "dumbells",
  "kettlebell",
  "pullup bar",
  "full gym",
  "resistance bands",
  "jump rope",
];
const SelectEquipment = ({ equipments, setEquipments, onSwipe }) => {
  const handleClick = (equipment) => {
    if (equipments.includes(equipment)) {
      setEquipments((_equipments) =>
        _equipments.filter((e) => e !== equipment)
      );
    } else {
      setEquipments((_equipments) => [..._equipments, equipment]);
    }
  };

  return (
    <div className={styles.container}>
      <Robot width={80} height={80} className={styles.icon} />
      <div className={styles.title}>What equipment would you like to use?</div>

      <div className={styles.list}>
        {equipmentList.map((equipment, key) => (
          <SelectButton
            title={equipment}
            selected={equipments.includes(equipment)}
            key={key}
            onClick={() => handleClick(equipment)}
          />
        ))}
      </div>

      <SwiperNav
        onBack={() => onSwipe(0)}
        onNext={() => equipments.length > 0 && onSwipe(2)}
      />
    </div>
  );
};

export default SelectEquipment;
